@use "../../../../../../_mixins" as *

.copy
  @include fontSize-16_18
  position: relative
  padding: 16px 56px 16px 16px
  border-radius: 12px
  border: 1px solid $border-inactive

  > div
    @include justifyVerticalCenter

  input
    display: none

.icon
  cursor: pointer
  position: absolute
  top: 0
  right: 0
  bottom: 0
  padding-left: 16px
  padding-right: 16px