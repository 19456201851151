@use "../../../mixins" as *

.tooltip
  color: $typography-active !important
  cursor: pointer
  position: relative

  .content
    position: absolute
    top: 100%
    left: -39px
    padding-top: 16px
    width: 100vw
    max-width: min(80vw, 500px)

    .contentInner
      @include shadowBold
      position: relative
      border-radius: 8px
      background-color: $fill-bg-quaternary
      z-index: 11

    .triangle
      position: absolute
      top: 1px
      left: 44px
      z-index: 12

@media screen and (max-width: 1023px)
  .contentInner
    padding: 12px

@media screen and (min-width: 1024px)
  .contentInner
    padding: 16px