@use "../../../../../mixins" as *

img
  max-width: 100%
  height: auto

.button
  margin-top: 16px

.imageSingle
  margin-left: -16px
  margin-right: -16px

@media screen and (max-width: 1023px)
  .blurred.overview
    padding: 16px

  .imageSingle
    padding-top: 16px

@media screen and (min-width: 1024px)
  .imageOverview
    margin-left: -6px
    margin-right: -6px

  .imageSingle
    padding-top: 20px