@use "../../../_mixins" as *

.chart,
.legendWrapper
  width: 100%
  display: flex
  justify-content: center

.legendWrapper
  margin-top: 8px

.legend
  display: flex !important
  flex-wrap: wrap !important
  justify-content: center !important

  *
    @include fontSize-12_14

  > div
    cursor: default
    margin: 2px 4px !important

    &:first-child
      margin-left: 0 !important

    &:last-child
      margin-right: 0 !important