.item
  display: flex
  margin: 12px 0

  .bullet
    flex: 0 0 34px

@media screen and (min-width: 1024px)
  .item
    .bullet
      flex: 0 0 42px