@use "../../_mixins" as *

.info
  @include borderRadius
  @include padding
  margin-top: 12px
  border: 1px solid $fill-active-primary

.title
  @include fontSize-h3
  display: flex
  margin-bottom: 12px
  > div
    @include justifyVerticalCenter

.titleText
  flex: 1

.close
  cursor: pointer