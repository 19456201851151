@use "../../../../../../mixins" as *

.tooltip
  @include fontSize-12_14
  font-weight: normal

  > div
    margin-bottom: 12px

    &:last-child
      margin-bottom: 0