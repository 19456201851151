@use "../../_mixins" as *

.head
  &.sticky
    position: fixed
    right: 0
    margin-bottom: 0
    z-index: 1
    @include shadow

    .headline,
    .actions
      margin-bottom: 0

.headline
  @include fontSize-18_24
  font-weight: bold
  color: $typography-title

.actions
  display: flex
  position: relative

  & > div
    display: inline-block

@media screen and (max-width: 1023px)
  .head
    &.sticky
      top: 68px
      padding: 7px 16px 12px 12px
      border-bottom-left-radius: 12px
      background-color: $fill-bg-primary

      .headline
        display: none

  .headline
    margin-bottom: 12px

  .actions
    flex-wrap: nowrap
    margin-bottom: 16px
    overflow-x: scroll
    -ms-overflow-style: none
    scrollbar-width: none

    &::-webkit-scrollbar
      display: none

    > *
      order: 3

      &:nth-last-child(2)
        order: 2

      &:last-child
        order: 1
        margin-left: 0
        margin-right: 12px

  .headStickySpacer
    height: 103px

@media screen and (min-width: 1024px)
  .head
    margin-bottom: 16px
    background-color: $fill-bg-secondary

    &.sticky
      top: 88px
      left: 0
      padding: 12px 32px 16px 32px

    > div
      display: flex
      margin: 0 auto
      max-width: $wrapper-max-width

      > div
        @include justifyVerticalCenter

        &:last-child
          flex: 1

  .headStickySpacer
    height: 67px

  .actions
    display: flex
    flex: 1
    flex-wrap: wrap
    justify-content: flex-end