@use "../../../../_mixins" as *

.video
  @include borderRadius
  margin-bottom: 16px
  overflow: hidden
  background-color: $fill-bg-primary

  iframe
    display: block
    border: 0