@use "../../../_mixins" as *


@media screen and (max-width: 1023px)
  .menu
    @include padding
    position: fixed
    top: 68px
    left: 0
    right: 0
    bottom: 0
    background-color: $fill-bg-primary
    z-index: 990

@media screen and (min-width: 1024px)
  .menu
    display: flex