@use "../../../../../mixins" as *

.grid
  @include borderRadius
  padding: 16px
  background-color: $fill-bg-primary

.red
  color: $typography-error

.list
  @include fontSize-12_14

  li
    margin-top: 3px
    margin-bottom: 0

@media screen and (min-width: 1024px)
  .grid
    display: grid
    grid-gap: 24px
    grid-template-columns: 1fr 1fr
    padding: 24px