@use "../../../../../_mixins" as *

.alert
  padding-top: 4px

.live
  @include fontSize-12_14
  display: flex

.dealIsLive
  color: $typography-success
  display: flex
  flex: 1

  div
    @include justifyVerticalCenter

  svg
    margin-right: 4px

.max
  div
    @include justifyVerticalCenter
    color: $typography-inactive