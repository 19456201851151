@use "../../../_mixins" as *

.sort
  position: relative
  margin-top: 5px

  > button
    border-color: $border-mute !important

    &:focus,
    &:focus-visible,
    &:focus-within,
    &:hover
      border-style: solid !important
      border-color: $border-mute !important

    .label
      @include fontSize-14_16