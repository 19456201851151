@use "../../../../_mixins" as *

.summary
  @include borderRadius
  background-color: $fill-bg-primary

.top
  @include borderDashed1pxBottom
  padding-bottom: 16px
  margin-bottom: 16px

.finalDeadline
  @include fontSize-12_14
  color: $typography-active
  margin-top: 16px

.investButton
  margin-top: 24px

@media screen and (max-width: 1023px)
  .summary
    padding: 16px
    margin-bottom: 16px
    border: 1px solid $border-mute

  .timeline
    display: none

@media screen and (min-width: 1024px)
  .summary
    @include shadow
    padding: 24px
    margin-bottom: 24px

  .timeline
    margin-top: 28px