@use "../../../../_mixins" as *

.tags
  @include fontSize-12_14
  color: $typography-caption
  display: flex
  margin-top: 12px

  > div
    @include justifyVerticalCenter

  svg
    margin-right: 8px