@use "../../../../../mixins" as *

.logoAndTitle
  display: flex

  > div
    @include justifyVerticalCenter

  .logo
    padding-right: 8px

  .title
    @include fontSize-16_18

@media screen and (max-width: 1023px)
  .logoAndTitle
    .logo
      img
        height: 22px

@media screen and (min-width: 1024px)
  .logoAndTitle
    .logo
      img
        height: 28px