@use "../../../mixins" as *

.document
  display: flex
  padding: 16px
  border: 1px solid $border-mute
  border-radius: 8px

  &.clickable
    cursor: pointer

    &:hover
      background-color: $fill-bg-tertiary

  .icon
    flex: 0 0 34px

  .content
    flex: 1

    > h3
      @include fontSize-16_18
      color: $typography-title

    > div
      @include fontSize-14_16
      color: $typography-body
      margin-top: 5px

  .download
    display: flex
    flex: 0 0 60px
    justify-content: flex-end
    padding-right: 8px