@use "modern-normalize" as *
@use "_variables" as *

body
  font-size: 14px
  line-height: 20px
  color: $typography-black
  background-color: $fill-bg-secondary
  margin: 0
  font-family: "Lato", sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

a
  text-decoration: none
  &:hover
    text-decoration: underline

h1,
h2,
h3,
h4,
h5,
h6
  font-size: 1rem
  margin: 0

ul
  padding: 0 0 0 18px
  margin: 0

  li
    margin: 0 0 12px 0

    &:last-child
      margin-bottom: 0

.background-fill-bg-primary
  background-color: $fill-bg-primary
.background-fill-bg-tertiary
  background-color: $fill-bg-tertiary
.background-icon-theme
  background-color: $icon-theme

.fill-icon-black
  fill: $icon-black
.fill-icon-caption
  fill: $typography-caption
.fill-icon-error
  fill: $icon-error
.fill-icon-inactive
  fill: $icon-inactive
.fill-icon-quaternary
  fill: $icon-quaternary
.fill-icon-success
  fill: $icon-success
.fill-icon-theme
  fill: $icon-theme
.fill-icon-theme-light
  fill: $icon-theme-light
.fill-icon-warning
  fill: $icon-warning
.fill-icon-white
  fill: $icon-white

.stroke-icon-theme-light
  stroke: $icon-theme-light

svg
  display: block

@media screen and (min-width: 1024px)
  body
    font-size: 18px
    line-height: 24px

  ul li
    margin-bottom: 16px