@use "../../../mixins" as *

.banner
  @include borderRadius
  padding: 16px
  margin-bottom: 16px
  border: 1px solid $border-neutral-primary
  background-color: $fill-bg-primary

.logo
  @include justifyVerticalCenter
  min-width: 72px

  img
    display: block

.title
  @include fontSize-14_16
  font-weight: bold
  color: $typography-title

.text
  @include fontSize-13_15
  color: $typography-caption


@media screen and (max-width: 379px)
  .logo
    margin-bottom: 5px

@media screen and (min-width: 380px)
  .banner
    display: flex

  .logo
    margin-right: 16px

@media screen and (max-width: 1023px)
  .banner
    &.desktop
      display: none

  .title
    margin-bottom: 4px

@media screen and (min-width: 1024px)
  .banner
    &.mobile
      display: none

  .title
    margin-bottom: 8px