@use "../../../_mixins" as *

@media screen and (max-width: 1023px)
  .head
    margin: -16px -16px 0 -16px
    padding: 16px 16px 1px 16px
    background-color: $fill-bg-primary

  .dashboard
    > div
      &:nth-child(2)
        margin: 0 -16px
        padding: 0 16px
        background-color: $fill-bg-primary

  .noInvestments
    > div
      &:last-child
        margin-top: 16px

@media screen and (min-width: 1024px)
  .noInvestments
    > div
      &:last-child
        margin-top: 32px