@use "../../../../../../mixins" as *

.table
  @include fontSize-14_16

  > div
    display: flex
    margin-top: 16px

    > div
      &:nth-child(1)
        color: $typography-body
        flex: 1

      &:nth-child(2)
        font-weight: 500
        color: $typography-title

.amountCommitted,
.amount
  padding-bottom: 16px
  border-bottom: 1px dashed $border-inactive

.notice
  margin-top: 16px

.total
  @include fontSize-16_18
  font-weight: bold
  color: $typography-title
  margin-top: 24px
  padding-top: 24px
  border-top: 1px solid $border-inactive

  > div
    font-weight: bold !important