.positionMobileAction
  &.closed
    display: none

@media screen and (max-width: 1023px)
  .positionSummary
    &.invest
      display: none

  .invest
    .desktop
      display: none

  .closed
    margin-top: 20px

@media screen and (min-width: 1024px)
  .positionMobileAction
    &.invest
      display: none

  .invest
    margin-top: 24px

    .mobile
      display: none

  .closed

    margin-top: 28px