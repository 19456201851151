@use "../../../../_mixins" as *

.description
  @include fontSize-12_14
  color: $typography-caption

@media screen and (min-width: 1024px)
  .description
    &.dashedBorder
      @include borderDashed1pxBottom
      padding-bottom: 12px
      margin-bottom: 14px