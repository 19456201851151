@use "../../../../_mixins" as *

.tile
  @include borderRadius
  margin-bottom: 16px
  background-color: $fill-bg-primary

  .inner
    p
      &:first-child
        margin-top: 0

      &:last-child
        margin-bottom: 0

.tileOpened
  composes: tile

.head
  @include fontSize-18_24
  display: flex
  font-weight: bold
  color: $typography-title
  padding-bottom: 16px

  > div
    @include justifyVerticalCenter

    &:first-child
      flex: 1

@media screen and (max-width: 1023px)
  .tile
    padding: 16px 16px 0 16px

  .tileOpened
    .head
      @include borderDashed1pxBottom

    .content
      grid-template-rows: 1fr

  .toggle
    cursor: pointer

  .content
    display: grid
    grid-template-rows: 0fr
    transition: grid-template-rows 0.4s ease-out

    .inner
      overflow: hidden

      > div
        padding: 16px 0

@media screen and (min-width: 1024px)
  .tile
    padding: 24px

  .toggle
    display: none !important