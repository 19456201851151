@use "../../../../mixins" as *

.card
  padding: 24px
  margin-bottom: 16px
  border-radius: 16px
  background-color: $fill-warning-secondary

@media screen and (max-width: 1023px)
  .card
    &.desktop
      display: none

@media screen and (min-width: 1024px)
  .card
    &.mobile
      display: none