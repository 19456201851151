@use "../../../../../_mixins" as *

.functions
  border-top-left-radius: 12px
  border-top-right-radius: 12px
  padding: 32px 16px 16px 16px
  position: fixed
  left: 0
  right: 0
  bottom: 0
  animation: slideInFromBottom 0.3s ease-out
  background-color: $fill-bg-primary
  z-index: 50

  &:before
    @include borderRadius
    width: 28px
    height: 4px
    content: ""
    position: absolute
    top: 10px
    left: 50%
    margin-left: -14px
    background-color: $mute-primary

.overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: $fill-overlay
  z-index: 40