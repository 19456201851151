@use "../../../mixins" as *

.tile
  margin-bottom: 36px

  &.authenticated
    padding: 24px
    border-radius: 12px
    background-color: $fill-bg-primary

  &:last-child
    margin-bottom: 0
    border-bottom: 0

.title
  @include fontSize-18_24
  font-weight: bold
  color: $typography-title
  margin-bottom: 16px