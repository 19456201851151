@use "../../../../../../_mixins" as *

.title
  @include fontSize-h3
  font-weight: bold

  .explanation
  @include fontSize-13_15
  color: $typography-caption
  margin-top: 6px
  margin-bottom: 24px

.selectTransferOrigin
  margin: 24px 0

  select
    font-size: 16px
    color: $typography-inverted
    padding: 10px 12px
    border-radius: 12px
    background-color: $fill-active-primary

.field
  margin-top: 16px

.label
  @include fontSize-12_14
  color: $typography-caption
  margin-bottom: 8px

.amountToPay
  margin-bottom: 32px

.amountToPayTitle
  margin-bottom: 16px

.alertExactAmount
  @include fontSize-12_14
  padding: 8px 10px
  margin-top: 24px
  border-radius: 8px
  background-color: $fill-neutral-secondary

.accountDetails
  .redNotice
    @include fontSize-16_18
    font-weight: bold
    color: $typography-error

@media screen and (max-width: 1023px)
  .selectTransferOrigin
    select
      width: 100%
      text-align: center
      margin-top: 8px

  .accountDetails
    .redNotice
      margin-top: 5px

@media screen and (min-width: 1024px)
  .explanation
    margin-top: 8px

  .selectTransferOrigin
    display: flex

    > div
      @include justifyVerticalCenter

      &:first-child
        flex: 1

      &:last-child
        padding-left: 32px

  .fields
    display: grid
    column-gap: 16px
    grid-template-columns: repeat(2, minmax(0, 1fr))

  .accountDetails
    display: flex

    .redNotice
      flex: 1
      text-align: right
      padding-left: 32px