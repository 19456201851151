@use "../../_mixins" as *

.notAuthenticated
  @include borderRadius
  background-color: $fill-bg-primary

.content
  padding: 16px

  h2
    font-size: 1.5rem
    margin-bottom: 12px

  h3
    font-size: 1.1rem
    margin-top: 24px

  p
    margin: 12px 0

  ol
    li
      margin: 6px 0

  *:last-child
    margin-bottom: 0 !important

.box
  font-style: italic
  padding: 14px 16px
  border-radius: 8px
  background-color: #DDDDDD

  p:first-child
    margin-top: 0