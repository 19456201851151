.tooltip
  max-width: 250px

.date
  display: block
  margin-bottom: 12px

.line
  padding-bottom: 8px

  strong
    display: inline-block
    padding-right: 5px

.description
  font-size: 0.85em
  margin-top: 7px
  padding-bottom: 5px

  li
    margin-bottom: 3px