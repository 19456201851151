@use "../../../mixins" as *

.banner
  @include borderRadius
  padding: 16px
  margin-bottom: 16px
  border: 1px solid $border-neutral-primary
  background-color: $fill-bg-primary

.logo
  @include justifyVerticalCenter
  min-width: 60px

  img
    display: block

.text
  @include fontSize-13_15
  color: $typography-caption

.error
  @include fontSize-13_15
  font-weight: bold
  color: $typography-error

.success
  margin-bottom: 16px

@media screen and (max-width: 379px)
  .logo
    margin-bottom: 5px

@media screen and (min-width: 380px)
  .banner
    display: flex

  .logo
    margin-right: 16px

@media screen and (max-width: 1023px)
  .text
    margin-bottom: 4px

@media screen and (min-width: 1024px)
  .text
    margin-bottom: 8px