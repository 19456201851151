@use "../../../../mixins" as *

.info
  color: $typography-inactive
  display: flex
  justify-content: center
  padding: 8px
  border-radius: 4px
  background-color: $fill-bg-secondary

  > div
    @include justifyVerticalCenter

.icon
  margin-right: 8px

.text
  color: $typography-inactive

@media screen and (max-width: 1023px)
  .info
    margin-top: 12px

    &.noBackgroundMobile
      padding: 0
      background-color: transparent

@media screen and (min-width: 1024px)
  .info
    font-size: 14px
    margin-top: 32px