@import "../../../mixins"

.alert
  margin-bottom: 24px

.form
  margin-bottom: 16px

.toBeConfirmed
  @include fontSize-16_18
  color: $typography-active
  display: flex
  border-radius: 8px
  background-color: $fill-success-secondary

  > div
    @include justifyVerticalCenter

    &:first-child
      padding-right: 12px

@media screen and (max-width: 1023px)
  .toBeConfirmed
    margin-top: 60px
    margin-bottom: 60px
    padding: 12px

@media screen and (min-width: 1024px)
  .form
    margin-bottom: 32px

  .toBeConfirmed
    padding: 16px