@use "../../../../../mixins" as *
@use "../../../../../tech/form/form-mixins.module" as *

.valuation
  @include borderDashed2pxBottom

.wrapper
  display: flex

  > div
    @include justifyVerticalCenter

    &:first-child
      flex: 1

      > div
        margin-bottom: 0

    &.removeWrapper
      flex: 0 0 34px

.remove
  cursor: pointer
  display: flex
  justify-content: flex-end

  &:hover
    svg
      path
        fill: $icon-error

@media screen and (max-width: 1023px)
  .valuation
    margin-bottom: 16px
    padding-bottom: 16px

@media screen and (min-width: 1024px)
  .valuation
    margin-bottom: 24px
    padding-bottom: 24px