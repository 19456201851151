@use "../../../_mixins" as *

@media screen and (max-width: 1023px)
  .wrapper
    @include shadow
    display: flex
    padding: 16px
    position: fixed
    left: 0
    right: 0
    bottom: 0
    background-color: $fill-bg-primary
    z-index: 10

    > button,
    > div
      width: 100%
      margin-top: 0

  .spacer
    height: 90px

@media screen and (min-width: 1024px)
  .wrapper
    margin-top: 24px

    &.rightOnDesktop
      display: flex
      justify-content: flex-end

      > div
        margin-top: 0

      button
        width: auto

        > div:last-child
          padding-left: 40px

  .spacer
    display: none