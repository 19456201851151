@use "../../../../../../../_mixins" as *

.sort
  cursor: pointer
  display: flex

  > div
    @include justifyVerticalCenter

    &.sortIcon
      margin-left: 4px

.title
  font-weight: bold
  color: $typography-title