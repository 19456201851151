@use "../../../../_mixins" as *

.input
  @include fontSize-16_18
  position: relative

  .currency,
  .clear
    position: absolute
    top: 0
    bottom: 0

  .currency
    @include justifyVerticalCenter
    font-weight: bold
    color: $typography-inactive
    left: 0
    padding-left: 16px
    padding-right: 12px
    border-right: 1px solid $border-inactive

  .number
    input
      width: 100%
      padding: 17px 65px 17px 80px
      border-radius: 12px
      border: 1px solid $border-inactive
      -moz-appearance: none
      -webkit-appearance: none

      &:focus,
      &:focus-visible
        border-color: $border-active
        outline: transparent

  .clear
    @include justifyVerticalCenter
    cursor: pointer
    right: 0
    padding-left: 12px
    padding-right: 16px

.minTicket
  padding-top: 5px
  color: $typography-inactive

.errors
  @include fontSize-12_14
  color: $typography-error
  margin-top: 12px

  li
    margin-bottom: 5px

@media screen and (max-width: 1023px)
  .amount
    margin-bottom: 32px

@media screen and (min-width: 1024px)
  .amount
    margin-bottom: 44px