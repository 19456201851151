@use "../../../../mixins" as *

.item
  display: flex
  margin-bottom: 24px

  &:last-child
    margin-bottom: 0

    .circle
      &:after
        display: none

  > div
    @include justifyVerticalCenter

  .circle
    width: 24px
    height: 24px
    position: relative
    border-radius: 9999px
    border: 1px solid $border-inactive
    background-color: $fill-bg-primary

    &:after
      position: absolute
      top: 100%
      left: 10px
      content: ""
      display: block
      width: 2px
      height: 25px
      background-color: $border-inactive

  .text
    @include fontSize-14_16
    color: $typography-inactive
    padding-left: 16px

  &.active
    .circle
      border-style: dashed
      border-color: $border-focus

    .text
      color: $typography-active

  &.done
    .circle
      border-color: $border-focus
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkNoZWNrIj4KPHBhdGggaWQ9IlZlY3RvciIgZD0iTTE0LjM1MzggNC44NTQwM0w2LjM1Mzc4IDEyLjg1NEM2LjMwNzM1IDEyLjkwMDUgNi4yNTIyIDEyLjkzNzQgNi4xOTE1IDEyLjk2MjZDNi4xMzA4MSAxMi45ODc3IDYuMDY1NzQgMTMuMDAwNyA2LjAwMDAzIDEzLjAwMDdDNS45MzQzMyAxMy4wMDA3IDUuODY5MjYgMTIuOTg3NyA1LjgwODU2IDEyLjk2MjZDNS43NDc4NiAxMi45Mzc0IDUuNjkyNzIgMTIuOTAwNSA1LjY0NjI4IDEyLjg1NEwyLjE0NjI4IDkuMzU0MDNDMi4wNTI0NiA5LjI2MDIxIDEuOTk5NzYgOS4xMzI5NiAxLjk5OTc2IDkuMDAwMjhDMS45OTk3NiA4Ljg2NzYgMi4wNTI0NiA4Ljc0MDM1IDIuMTQ2MjggOC42NDY1M0MyLjI0MDEgOC41NTI3MSAyLjM2NzM1IDguNSAyLjUwMDAzIDguNUMyLjYzMjcyIDguNSAyLjc1OTk2IDguNTUyNzEgMi44NTM3OCA4LjY0NjUzTDYuMDAwMDMgMTEuNzkzNEwxMy42NDYzIDQuMTQ2NTNDMTMuNzQwMSA0LjA1MjcxIDEzLjg2NzQgNCAxNCA0QzE0LjEzMjcgNCAxNC4yNiA0LjA1MjcxIDE0LjM1MzggNC4xNDY1M0MxNC40NDc2IDQuMjQwMzUgMTQuNTAwMyA0LjM2NzYgMTQuNTAwMyA0LjUwMDI4QzE0LjUwMDMgNC42MzI5NiAxNC40NDc2IDQuNzYwMjEgMTQuMzUzOCA0Ljg1NDAzWiIgZmlsbD0iI2ZmZmZmZiIvPgo8L2c+Cjwvc3ZnPgo=")
      background-position: center center
      background-repeat: no-repeat
      background-color: $fill-active-primary