@use "../../../../_mixins" as *

.icon
  width: 24px
  height: 24px
  padding: 3px
  background-color: $fill-active-secondary
  border-radius: 100%

@media screen and (min-width: 1024px)
  .icon
    width: 32px
    height: 32px
    padding: 5px

    svg
      width: 22px
      height: 22px