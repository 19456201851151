@use "../../../../_mixins" as *

.documents
  @include borderRadius
  padding: 16px
  margin-bottom: 16px
  background-color: $fill-bg-primary

  h4
    @include fontSize-18_24
    font-weight: bold
    margin-bottom: 16px

@media screen and (min-width: 1024px)
  .documents
    padding: 24px