@use "../../../../_mixins" as *

.modal
  @include borderRadius
  background-color: $fill-bg-primary

.content
  display: flex

  > div:first-child
    flex: 1

  .close
    display: flex
    justify-content: flex-end
    width: 56px

    > div
      cursor: pointer

.actions
  display: flex
  justify-content: flex-end

  > div:last-child
    margin-left: 16px

@media screen and (max-width: 1023px)
  .modal
    padding: 16px

  .actions
    margin-top: 16px

@media screen and (min-width: 1024px)
  .modal
    max-width: 600px
    padding: 24px

  .actions
    margin-top: 24px