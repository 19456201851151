@use "../../../_mixins" as *

.title
  font-size: 16px
  font-weight: 500
  color: $typography-title
  margin-bottom: 16px

@media screen and (min-width: 1024px)
  .title
    font-size: 21px