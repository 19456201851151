@use "../../../_mixins" as *

@media screen and (max-width: 1023px)
  .timeline
    margin-top: 18px
    margin-bottom: 32px

@media screen and (min-width: 1024px)
  .container
    display: flex

    .left
      width: 68%
      order: 1

    .right
      width: 32%
      order: 2
      padding-left: 24px

  .timeline
    display: none