@use "../../../../mixins" as *

.kycBanner
  @include borderRadius
  @include padding-16
  cursor: pointer
  display: flex
  background-color: $fill-bg-primary
  border: 1px solid $border-warning
  margin-bottom: 24px

.text
  flex: 1
  padding-right: 8px

.textHead
  @include fontSize-14_16
  font-weight: bold
  margin-bottom: 4px

.textBody
  @include fontSize-13_15
  color: $typography-caption

.go
  display: flex

.goText
  @include fontSize-14_16
  color: $typography-active
  display: none

.goArrow
  @include justifyVerticalCenter

@media screen and (min-width: 1024px)
  .kycBanner
    margin-bottom: 32px

  .textHead
    margin-bottom: 8px

  .goText
    @include justifyVerticalCenter
    margin-right: 8px