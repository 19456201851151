@use "../../../../mixins" as *

.description
  @include borderRadius
  @include fontSize-14_16
  border: 1px solid $border-mute
  background-color: $fill-bg-primary

  a
    font-weight: bold
    color: $typography-neutral

  > div:last-child
    margin-bottom: 0

@media screen and (max-width: 1023px)
  .description
    padding: 16px
    margin-bottom: 16px

    > div
      margin-bottom: 8px

@media screen and (min-width: 1024px)
  .description
    padding: 24px
    margin-bottom: 24px

    > div
      margin-bottom: 12px