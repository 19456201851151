@use "../../../../_mixins" as *

.head
  display: flex

.logoAndTitle
  display: flex
  flex: 1

  > div
    @include justifyVerticalCenter

  .logo
    padding-right: 8px

    img
      display: block
      width: auto
      height: 30px

  .title
    @include fontSize-16_18
    color: $typography-inverted

@media screen and (min-width: 1024px)
  .logoAndTitle
    flex: none
    margin-right: 10px
    padding-top: 30px

    .logo
      img
        height: 50px

  .title
    display: none !important