@use "../../../../mixins" as *

.title
  @include fontSize-h3
  font-weight: bold
  color: $typography-title
  margin-bottom: 16px

.subtitle
  @include fontSize-16_18
  font-weight: bold
  color: $typography-title

.paragraph
  @include fontSize-16_18
  margin-bottom: 16px

  &:last-child
    margin-bottom: 0