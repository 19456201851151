@use "../../../../../../_mixins" as *

.head
  display: flex

  > div
    @include justifyVerticalCenter

  select
    font-size: 16px
    color: $typography-inverted
    padding: 10px 12px
    border-radius: 12px
    background-color: $fill-active-primary

.lock
  margin-top: 24px

@media screen and (max-width: 1023px)
  .head
    > div
      &:first-child
        flex: 1

@media screen and (min-width: 1024px)
  .head
    > div
      &:first-child
        margin-right: 16px