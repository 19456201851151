@use "../../../../../_mixins" as *

.update
  padding-top: 16px
  padding-bottom: 16px
  border-bottom: 1px solid $border-mute

  &:first-child
    padding-top: 0

.head
  @include fontSize-h4
  font-weight: bold
  cursor: pointer
  display: flex
  padding-top: 8px
  padding-bottom: 8px

  > div
    @include justifyVerticalCenter

    &:first-child
      flex: 1

    &:last-child
      padding-left: 16px

.content
  display: grid
  grid-template-rows: 0fr
  transition: grid-template-rows 0.4s ease-out

  a
    color: $typography-dark-purple

  .inner
    overflow: hidden

  &.opened
    grid-template-rows: 1fr

.files
  display: grid
  gap: 16px
  margin-top: 16px

@media screen and (max-width: 1023px)
  .files
    grid-template-columns: repeat(1, minmax(0, 1fr))

@media screen and (min-width: 1024px)
  .files
    grid-template-columns: repeat(2, minmax(0, 1fr))