@use "../../../../_mixins" as *

.link
  @include fontSize-16_18
  @include padding-bottom
  padding-top: 16px
  font-weight: bold
  color: $typography-caption

  a
    color: $typography-active