@use "../../../../../../mixins" as *

.addOwnInvestment
  margin-top: 5px

  button
    border-color: $border-mute !important

@media screen and (max-width: 1023px)
  .addOwnInvestment
    margin-right: 12px

@media screen and (min-width: 1024px)
  .addOwnInvestment
    margin-right: 16px