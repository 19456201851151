@use "variables" as *
@forward "variables"

@mixin borderDashed1pxTop
  border-top: 1px dashed $border-mute

@mixin borderDashed1pxBottom
  border-bottom: 1px dashed $border-mute

@mixin borderDashed2pxTop
  border-top: 2px dashed $border-mute

@mixin borderDashed2pxBottom
  border-bottom: 2px dashed $border-mute

@mixin borderRadius
  border-radius: 16px
@mixin borderRadius-top
  border-top-left-radius: 16px
  border-top-right-radius: 16px
@mixin borderRadius-left
  border-top-left-radius: 16px
  border-bottom-left-radius: 16px

@mixin buttonNotStyled
  border: none
  margin: 0
  padding: 0
  width: auto
  overflow: visible
  background: transparent
  color: inherit
  font: inherit
  line-height: normal
  -webkit-font-smoothing: inherit
  -moz-osx-font-smoothing: inherit
  -webkit-appearance: none

@mixin fontSize-10_12
  font-size: 10px
  line-height: 14px
  @media screen and (min-width: 1024px)
    font-size: 12px
    line-height: 18px
@mixin fontSize-12_14
  font-size: 12px
  line-height: 16px
  @media screen and (min-width: 1024px)
    font-size: 14px
    line-height: 20px
@mixin fontSize-13_15
  font-size: 13px
  line-height: 16px
  @media screen and (min-width: 1024px)
    font-size: 15px
    line-height: 20px
@mixin fontSize-14_16
  font-size: 14px
  line-height: 20px
  @media screen and (min-width: 1024px)
    font-size: 16px
    line-height: 20px
@mixin fontSize-16_18
  font-size: 16px
  line-height: 20px
  @media screen and (min-width: 1024px)
    font-size: 18px
    line-height: 24px
@mixin fontSize-18_24
  font-size: 18px
  line-height: 24px
  @media screen and (min-width: 1024px)
    font-size: 24px
    line-height: 36px
@mixin fontSize-h4
  font-size: 18px
  line-height: 24px
  @media screen and (min-width: 1024px)
    font-size: 21px
    line-height: 28px
@mixin fontSize-h3
  font-size: 21px
  line-height: 28px
  @media screen and (min-width: 1024px)
    font-size: 24px
    line-height: 36px
@mixin fontSize-h2
  font-size: 24px
  line-height: 36px
@mixin fontSize-h1
  font-size: 28px
  line-height: 44px
  @media screen and (min-width: 1024px)
    font-size: 34px
    line-height: 51px

@mixin gridTwoColumns
  grid-template-columns: repeat(2, minmax(0, 1fr))

@mixin justifyVerticalCenter
  display: flex
  flex-direction: column
  justify-content: center

@mixin padding
  padding: 16px
  @media screen and (min-width: 1024px)
    padding: 32px
@mixin padding-top
  padding-top: 16px
  @media screen and (min-width: 1024px)
    padding-top: 32px
@mixin padding-left
  padding-left: 16px
  @media screen and (min-width: 1024px)
    padding-left: 32px
@mixin padding-right
  padding-right: 16px
  @media screen and (min-width: 1024px)
    padding-right: 32px
@mixin padding-bottom
  padding-bottom: 16px
  @media screen and (min-width: 1024px)
    padding-bottom: 32px
@mixin padding-16
  padding: 16px

@mixin shadow
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15)

@mixin shadowBold
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25)

@mixin shadowHigh
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15)

@mixin wrapper
  margin: 0 auto
  max-width: $wrapper-max-width