@use "../../../mixins" as *

.graph
  cursor: crosshair
  overflow: visible

.axis text
  @include fontSize-12_14
  color: $typography-caption

.verticalLine
  width: 1px
  height: 100%
  position: absolute
  top: 0
  left: 0
  border-left: 1px dashed #35477d