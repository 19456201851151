@use "../../../../../../mixins" as *

@media screen and (max-width: 1023px)
  .investment
    border-radius: 8px
    padding: 16px
    margin-bottom: 16px
    background-color: $fill-bg-primary

    &:last-child
      margin-bottom: 0