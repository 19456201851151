@use "../../_mixins" as *

.dot
  &.active
    > div
      background-color: $fill-success-primary

  &.inactive
    > div
      background-color: $mute-primary

  &.neutral
    > div
      background-color: $fill-neutral-primary

  > div
    width: 8px
    height: 8px
    border-radius: 100%