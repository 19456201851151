@use "../../../../../../../mixins" as *

@media screen and (max-width: 1023px)
  .stats
    > div
      @include borderDashed1pxBottom
      color: $typography-caption
      display: flex
      padding: 16px 0

      &:first-child
        padding-top: 12px

      &:last-child
        border-bottom: 0
        padding-bottom: 12px

      > div
        &:first-child
          flex: 1

  .irr,
  .moic
    > div
      @include justifyVerticalCenter

  .triangle
    margin-top: 1px