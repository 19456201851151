.description
  padding-bottom: 16px

.button
  padding-top: 16px

@media screen and (min-width: 1024px)
  .description
    padding-bottom: 24px

  .button
    padding-top: 24px