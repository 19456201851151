@use "../../../../_mixins" as *

.function
  cursor: pointer
  display: flex
  padding: 16px 12px

  > div
    @include justifyVerticalCenter

    &:first-child
      padding-right: 12px

.functionLabel
  flex: 1
  font-size: 16px