@use "../../../_mixins" as *

.summary
  @include borderRadius
  background-color: $fill-bg-primary

.top
  padding-bottom: 16px

.middle,
.fees
  @include borderDashed1pxBottom
  padding-bottom: 16px
  margin-bottom: 16px

.bottom
  @include fontSize-14_16
  display: flex
  padding-top: 8px

  > div
    &:first-child
      color: $typography-caption
      flex: 1

    &:last-child
      font-weight: bold
      color: $typography-title

@media screen and (max-width: 1023px)
  .summary
    padding: 16px
    margin-bottom: 16px
    border: 1px solid $border-mute

@media screen and (min-width: 1024px)
  .summary
    @include shadow
    padding: 24px
    margin-bottom: 24px