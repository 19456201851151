@use "../../../../_mixins" as *

.item,
.itemHideOnDesktop
  font-size: 16px
  color: $typography-inactive
  text-decoration: none !important
  display: flex
  padding: 16px
  cursor: pointer

.itemActive,
.itemActiveHideOnDesktop
  composes: item
  font-weight: bold
  color: $typography-active
  .icon
    svg path
      fill: $icon-theme !important

.icon
  margin-right: 8px

.icon,
.text
  @include justifyVerticalCenter

@media screen and (max-width: 1023px)
  .item,
  .itemHideOnDesktop
    margin-bottom: 8px

    &:last-child
      margin-bottom: 0

@media screen and (min-width: 1024px)
  .item
    &:hover
      color: $typography-active

      .icon
        > div > div
          background-color: $icon-theme

        svg path
          fill: $icon-theme !important

  .itemHideOnDesktop,
  .itemActiveHideOnDesktop
    display: none