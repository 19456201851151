@use "../../_mixins" as *

.actionLine
  @include fontSize-16_18
  @include padding-top
  font-weight: bold
  color: $typography-caption
  padding-bottom: 24px

  a
    color: $typography-active

.actionLineWithDashedTopLine
  composes: actionLine
  @include borderDashed2pxTop

@media screen and (min-width: 1024px)
  .actionLine
    padding-bottom: 0