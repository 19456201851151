@use "../../../../../../../mixins" as *

@media screen and (max-width: 1023px)
  .head
    cursor: pointer
    display: flex

    > div
      @include justifyVerticalCenter

      &:nth-child(1)
        flex: 1

  .logoAndStatus
    display: flex
    justify-content: space-between
    padding-right: 5px

    &.marginBottom
      margin-bottom: 8px