@use "../../../../_mixins" as *

.disclaimer
  @include borderRadius
  padding: 16px
  margin-bottom: 16px
  background-color: $fill-bg-primary

.title
  display: flex
  margin-bottom: 8px

  .titleText
    @include fontSize-18_24
    font-weight: bold

  > div
    @include justifyVerticalCenter

.info
  padding-left: 6px

.readFirst
  color: $typography-inactive

@media screen and (min-width: 1024px)
  .disclaimer
    padding: 24px