@use "../../../../_mixins" as *

.qa
  @include borderRadius
  padding: 16px
  margin-bottom: 16px
  background-color: $fill-bg-primary

  h4
    @include fontSize-18_24
    font-weight: bold
    margin-bottom: 8px

.input
  position: relative
  margin-top: 16px

  input
    width: 100%
    padding: 16px 50px 16px 16px
    border-radius: 12px
    border: 1px solid $border-inactive

  button
    @include buttonNotStyled
    cursor: pointer
    position: absolute
    top: 0
    right: 0
    bottom: 0
    padding: 16px

@media screen and (min-width: 1024px)
  .qa
    padding: 24px