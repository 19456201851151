@use "../../../../_mixins" as *

.headElement
  > div:first-child
    margin-bottom: 0
    white-space: nowrap

.title
  display: flex

  > div
    @include justifyVerticalCenter

.tooltip
  @include fontSize-12_14

  button
    border-color: $border-mute !important

.actions
  > div
    display: flex
    flex-wrap: wrap
    justify-content: flex-end

@media screen and (max-width: 1023px)
  .head
    margin: -24px -16px 0 -16px
    padding: 16px 16px 1px 16px
    background-color: $fill-bg-primary

  .title
    margin-bottom: 8px

  .actions
    margin-right: -12px
    margin-bottom: 12px

@media screen and (min-width: 1024px)
  .head
    display: flex

    > div
      @include justifyVerticalCenter

  .tooltip
    padding-top: 1px

  .actions
    position: relative
    flex: 1
    padding-left: 16px
    margin-right: -16px

    > div
      flex: 1
      padding-bottom: 5px