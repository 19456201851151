@use "../../../mixins" as *

.empty
  @include borderRadius
  text-align: center
  padding: 50px 15px 60px 15px
  background-color: $fill-bg-primary
  border: 1px dashed $border-active

  > div
    font-weight: bold
    margin-top: 18px
    margin-bottom: 12px

  img
    max-width: 75%

@media screen and (min-width: 1024px)
  .empty
    padding-top: 70px
    padding-bottom: 80px