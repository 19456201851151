@use "../../../../../mixins" as *

.summary
  display: grid

.title
  @include fontSize-h3
  font-weight: bold
  color: $typography-title
  margin-bottom: 9px

@media screen and (max-width: 1023px)
  .summary
    gap: 8px
    grid-template-columns: repeat(6, 1fr)
    padding-top: 7px

    > div
      &:nth-child(1),
      &:nth-child(3)
        grid-column: 1 / 4

      &:nth-child(2),
      &:nth-child(4)
        grid-column: 4 / 7

@media screen and (min-width: 1024px)
  .summary
    grid-template-columns: repeat(2, 1fr)
    gap: 24px

    &.withSubscription
      grid-template-columns: repeat(4, 1fr)