@use "../../../../_mixins" as *

.desktop
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 40
  background-color: $fill-overlay

.container
  @include justifyVerticalCenter
  height: 100%

  > div
    display: flex
    justify-content: center

    > div
      @include borderRadius
      width: 644px
      padding: 24px
      background-color: $fill-bg-primary

.head
  display: flex
  margin-bottom: 24px

  > div:first-child
    flex: 1

.title
  @include fontSize-16_18
  font-weight: bold
  color: $typography-title
  margin-bottom: 8px

.description
  @include fontSize-14_16

.close
  padding-left: 24px

  svg
    cursor: pointer