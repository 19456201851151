@use "../../_mixins" as *

.filter
  display: flex
  justify-content: flex-end
  margin-top: 5px

  > button
    border-color: $border-mute !important

    &:focus,
    &:focus-visible,
    &:focus-within,
    &:hover
      border-style: solid !important
      border-color: $border-mute !important

    .label
      @include fontSize-14_16

@media screen and (max-width: 1023px)
  .filter
    margin-right: 12px

@media screen and (min-width: 1024px)
  .filter
    margin-right: 16px