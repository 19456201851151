@use "../../../_mixins" as *

.fileUpload
  @include borderRadius
  cursor: pointer
  padding: 16px
  border: 1px solid $border-mute

.textAndIcon
  display: flex

.text
  flex: 1

.label
  @include fontSize-16_18
  font-weight: bold
  color: $typography-title
  margin-bottom: 8px

.description
  @include fontSize-14_16

.icon
  @include justifyVerticalCenter
  padding-left: 24px

.alert
  margin-top: 12px

@media screen and (min-width: 1024px)
  .fileUpload
    @include justifyVerticalCenter
    height: 100%
    padding: 24px