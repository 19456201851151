@use "../../../../../../_mixins" as *

.row
  color: $typography-active
  cursor: pointer

  > div
    border-top: 1px solid transparent
    border-bottom: 1px solid transparent

    &:first-child
      border-left: 1px solid transparent
      border-top-left-radius: 4px
      border-bottom-left-radius: 4px

    &:last-child
      border-right: 1px solid transparent
      border-top-right-radius: 4px
      border-bottom-right-radius: 4px

  &:hover
    > div
      border-color: $border-mute

    .caret
      svg
        visibility: visible

.netValue
  display: flex

  > div
    &:last-child
      margin-left: 12px

.moic,
.irr
  display: flex

  > div
    @include justifyVerticalCenter

    &:last-child
      margin-left: 6px

.caret
  width: 16px

  svg
    visibility: hidden