@use "../../_mixins" as *

.layout
  @include padding-left
  @include padding-right
  @include padding-bottom
  min-height: calc(100vh - 64px)
  padding-top: 92px

  &.whiteBackground
    background-color: $fill-bg-primary

.layoutInner
  @include wrapper
  position: relative

@media screen and (min-width: 1024px)
  .layout
    min-height: calc(100vh - 68px)
    padding-top: 120px