@use "../../../../_mixins" as *

.tile
  @include borderRadius
  margin-bottom: 16px
  padding: 16px
  border: 1px solid $border-mute
  background-color: $fill-bg-primary

.head
  @include fontSize-h4
  display: flex
  font-weight: bold
  color: $typography-title
  cursor: pointer

  > div
    @include justifyVerticalCenter

    &:first-child
      flex: 1

.title
  @include fontSize-h4
  font-weight: bold

.body
  overflow: hidden

  > div
    margin-top: -100%
    padding-top: 8px
    transition: margin-top 0.4s ease-in-out

.bodyOpened
  composes: body

  > div
    margin-top: 0

@media screen and (min-width: 1024px)
  .tile
    padding: 24px