@use "../../mixins" as *

.slideDeck
  position: relative

  img
    display: block

  &.fullPage
    @include justifyVerticalCenter
    width: auto !important
    height: auto !important
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1000
    background-color: #000

    .page
      background-color: #000

.page
  position: absolute
  top: 0
  left: 0
  z-index: 0
  visibility: hidden

  &.active
    visibility: visible

.navigation
  position: absolute
  left: 0
  right: 0
  bottom: 0
  z-index: 10
  display: flex
  justify-content: center

  > div
    @include shadow
    display: flex
    border-radius: 12px
    background-color: $fill-bg-primary

    > div
      @include justifyVerticalCenter

  button
    @include buttonNotStyled
    cursor: pointer

.actions
  position: absolute
  right: 0
  bottom: 0
  z-index: 10

  button
    @include buttonNotStyled
    @include shadow
    cursor: pointer
    border-radius: 12px
    background-color: $fill-bg-primary

    &:last-child
      margin-left: 8px

@media screen and (max-width: 1023px)
  .actions,
  .navigation
    margin-bottom: 16px

    button
      padding: 8px

  .actions
    padding-right: 16px

  .pages
    margin-left: 4px
    margin-right: 4px

@media screen and (min-width: 1024px)
  .actions,
  .navigation
    margin-bottom: 24px

    button
      padding: 12px

  .actions
    padding-right: 24px

    button
      &:last-child
        margin-left: 16px

  .pages
    margin-left: 8px
    margin-right: 8px