@use "../../../../../_mixins" as *

.fees
  > div
    display: flex

    > div
      &:first-child
        flex: 1

      &:last-child
        font-weight: bold

.amount,
.placementFee
  > div
    &:first-child
      @include fontSize-14_16

    &:last-child
      @include fontSize-16_18

.amount
  padding-bottom: 12px
  border-bottom: 1px dashed $border-inactive

.placementFee
  padding-top: 12px
  padding-bottom: 16px
  border-bottom: 1px solid $border-inactive

.total
  @include fontSize-16_18
  font-weight: bold
  padding-top: 16px

@media screen and (max-width: 1023px)
  .alert,
  .fees
    margin-top: 32px

@media screen and (min-width: 1024px)
  .alert,
  .fees
    margin-top: 40px

  .amount
    padding-bottom: 16px

  .placementFee
    padding-top: 16px
    padding-bottom: 24px

  .total
    padding-top: 24px