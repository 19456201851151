@use "../../../_mixins" as *

.title
  @include fontSize-h4
  font-weight: bold
  color: $typography-title
  margin-bottom: 8px

.initializationWrapper
  display: flex
  justify-content: center

.generalDataTeaser,
.initialization
  width: 100%
  max-width: 870px

.complete
  @include fontSize-h1
  font-weight: bold
  margin: 24px 0

@media screen and (max-width: 1023px)
  .generalDataTeaser
    margin-bottom: 16px

@media screen and (min-width: 1024px)
  .title
    margin-bottom: 12px

  .generalDataTeaser
    margin-bottom: 24px

  .content
    display: flex

    .left
      width: auto
      order: 1

    .right
      flex: 0 0 350px
      order: 2
      margin-left: 32px

  .complete
    margin-top: 0
    margin-bottom: 32px