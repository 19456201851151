@use "../../../../_mixins" as *

.badge
  @include fontSize-12_14
  color: $typography-caption
  display: flex
  padding-top: 12px

  > div
    display: flex
    padding: 8px 12px
    background-color: $fill-bg-tertiary

.logo,
.text
  @include justifyVerticalCenter

.logo
  padding-right: 4px

  img
    display: block
    height: 10px

.text
  > div
    line-height: 0

@media screen and (max-width: 1023px)
  .badge
    > div
      border-top-right-radius: 16px
      border-bottom-left-radius: 16px

@media screen and (min-width: 1024px)
  .badge
    position: absolute
    top: 0
    left: 0
    padding-top: 0

    > div
      border-top-left-radius: 15px
      border-bottom-right-radius: 16px

  .logo
    padding-top: 1px

    img
      height: 11px