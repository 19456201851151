@use "../../../../../mixins" as *

.status
  @include justifyVerticalCenter

.badge
  @include fontSize-12_14
  text-align: center
  width: 65px
  padding: 3px 8px
  border-radius: 13px

  &.active
    color: $typography-success
    background-color: $fill-success-tertiary

  &.dead
    color: $typography-error
    background-color: $fill-error-tertiary

  &.exit
    color: $typography-neutral
    background-color: $fill-neutral-tertiary
