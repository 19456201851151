@use "../../_mixins" as *

.breadcrumb
  @include padding-top
  @include padding-left
  @include padding-right
  padding-bottom: 29px
  display: flex
  position: fixed
  top: 88px
  left: 0
  right: 0
  background-color: $fill-bg-secondary
  z-index: 30

  > div
    width: 100%
    display: flex
    max-width: $wrapper-max-width
    margin: 0 auto

  .elementWrapper
    display: flex

    > div
      @include justifyVerticalCenter
      font-size: 15px
      margin-right: 8px

      a,
      span
        color: $typography-inactive

      &:last-child
        a,
        span
          color: $typography-title

  .arrow
    margin-right: 12px

    > *
      cursor: pointer

    svg
      margin-top: -1px

  .arrow,
  .caret
    padding-top: 1px

.spacer
  height: 53px

@media screen and (max-width: 1023px)
  .breadcrumb,
  .spacer
    display: none