@use "../../../../mixins" as *

.data
  @include borderRadius
  @include fontSize-12_14
  color: $typography-caption
  padding: 16px
  max-width: 450px
  background-color: $fill-bg-secondary

.emailAndPhone
  display: flex
  flex-wrap: wrap

  .spacer
    @include justifyVerticalCenter
    margin-left: 6px
    margin-right: 6px
    padding-top: 1px

    > div
      @include borderRadius
      width: 4px
      height: 4px
      background-color: #D9D9D9

.address,
.companyName
  margin-top: 8px

@media screen and (min-width: 1024px)
  .emailAndPhone
    .spacer
      margin-left: 8px
      margin-right: 8px