@use "../../../../../mixins" as *

@media screen and (max-width: 1023px)
  .desktop
    display: none

@media screen and (min-width: 1024px)
  .noInvestments
    margin-top: 32px

  .table
    margin-top: 24px

  .head
    display: flex

    > div
      @include justifyVerticalCenter

    .title
      @include fontSize-18_24
      font-weight: bold
      color: $typography-title

      > div
        display: flex

      .subTitle
        @include fontSize-12_14
        font-weight: normal
        color: $typography-caption
        padding-left: 12px
        display: flex
        flex-direction: column
        justify-content: center

  .actions
    flex: 1
    position: relative
    margin-top: -5px
    margin-right: -16px
    padding-bottom: 5px

    > div
      display: flex
      flex-wrap: wrap
      justify-content: flex-end

  .table
    width: 100%
    display: table

    > div
      display: table-row

      > div
        display: table-cell
        vertical-align: middle
        padding: 24px 12px

        &:first-child
          margin-left: 0

        &:last-child
          margin-right: 0

  .spacer
    > div
      column-span: all
      padding: 6px !important