@use "../../../../../../../mixins" as *

@media screen and (max-width: 1023px)
  .invested
    color: $typography-caption
    display: flex
    margin-top: 16px
    margin-bottom: 12px
    padding: 16px
    border-radius: 8px
    border: 1px solid $border-mute

  .amount
    font-weight: bold
    flex: 1