@use "../../_mixins" as *

.button
  display: flex
  flex-wrap: nowrap
  justify-content: center
  padding: 16px
  border-width: 1px
  border-style: solid
  border-radius: 12px
  background-color: white
  appearance: none
  -moz-appearance: none
  -webkit-appearance: none

  &:focus,
  &:focus-visible,
  &:focus-within
    border-color: $border-focus !important
    border-style: dashed !important

  > div
    @include justifyVerticalCenter
    align-self: stretch

.iconPositionRight
  > .label
    order: 1

  > .icon
    order: 2
    padding-left: 8px
    padding-right: 0 !important

.icon
  padding-right: 12px

.label
  white-space: nowrap

.withWordWrap
  .label
    white-space: normal

.arrow
  padding-left: 12px

/**** Size ****/
.minimal
  @include fontSize-12_14
  padding: 5px 8px

  .icon
    svg
      width: 14px
      height: 14px

  .icon
    padding-right: 4px

  .arrow
    padding-left: 4px

.small
  @include fontSize-14_16
  padding-top: 12px
  padding-bottom: 12px

  .icon
    svg
      width: 16px
      height: 16px

  .icon
    padding-right: 8px

  .arrow
    padding-left: 8px

.medium
  @include fontSize-16_18
  font-weight: bold

.large
  font-size: 18px
  font-weight: bold

/**** State ****/
.active
  cursor: pointer

.inactive
  cursor: pointer

.disabled
  cursor: not-allowed

/**** Style ****/
.primary
  color: $typography-inverted

  &.active
    border-color: $fill-active-primary
    background-color: $fill-active-primary

  &.disabled
    border-color: $fill-inactive-secondary
    background-color: $fill-inactive-secondary

  &.inactive
    border-color: $fill-inactive-primary
    background-color: $fill-inactive-primary

  .arrow svg > path,
  .icon svg > path
    fill: $typography-inverted

.secondary
  &.active
    color: $typography-active
    border-color: $border-active

    &:hover
      background-color: $fill-hover

    .arrow svg > path,
    .icon svg > path
      fill: $icon-theme

  &.disabled
    color: $typography-disabled
    border-color: $border-disabled

    .arrow svg > path,
    .icon svg > path
      fill: $icon-disabled

  &.inactive
    color: $typography-inactive
    border-color: $border-inactive

    .arrow svg > path,
    .icon svg > path
      fill: $typography-inactive

.tertiary
  border-color: transparent

  &.active
    color: $typography-active
    background-color: transparent

  &.disabled
    color: $typography-disabled

    .arrow svg > path,
    .icon svg > path
      fill: $icon-disabled

  &.inactive
    color: $typography-inactive

    .arrow svg > path,
    .icon svg > path
      fill: $typography-inactive

  &:focus,
  &:focus-visible,
  &:focus-within
    border-style: dashed

  .arrow svg > path,
  .icon svg > path
    fill: $icon-theme

/**** Width ****/
.full
  width: 100%