@use "../../../../_mixins" as *

.tooltip
  @include fontSize-12_14

  strong
    display: block
    margin-bottom: 4px

  small
    @include fontSize-12_14