@use "../../../_mixins" as *

.loadingBar
  @include borderRadius
  width: 100%
  height: 4px
  overflow: hidden

  div
    @include borderRadius
    width: 100%
    height: 100%
    animation: indeterminateAnimation 2s infinite linear
    transform-origin: 0 50%

@keyframes indeterminateAnimation
  0%
    transform: translateX(0) scaleX(0)
  40%
    transform: translateX(0) scaleX(0.4)
  100%
    transform: translateX(100%) scaleX(0.5)