@use "../../../../../../_mixins" as *

.tile
  padding: 8px
  border-radius: 8px
  border: 1px solid $border-mute

.whiteBackground
  background-color: $fill-bg-primary

.title
  @include fontSize-12_14
  color: $typography-inactive
  display: flex
  margin-bottom: 4px

  > div
    @include justifyVerticalCenter

.text
  font-size: 18px
  font-weight: bold
  color: $typography-active

@media screen and (min-width: 1024px)
  .tile
    padding: 24px

  .text
    font-size: 28px