@use "../../_form-mixins.module" as *

.label
  @include label

  select
    @include input
    @include fontSize-16_18
    color: $typography-black
    padding-right: 40px
    background-color: $fill-bg-primary
    -moz-appearance: none
    -webkit-appearance: none

    option
      color: $typography-black

.select
  position: relative

  svg
    position: absolute
    top: 20px
    right: 16px