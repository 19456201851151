@use "../../../../_mixins" as *

@media screen and (max-width: 1023px)
  .tabs
    display: flex
    margin: 0 -16px
    padding: 0 16px
    background-color: $fill-bg-primary

  .tab
    color: $typography-inactive
    position: relative
    margin-right: 12px
    padding-top: 7px
    padding-bottom: 6px

    &:last-child
      margin-right: 0

    &.active
      font-weight: bold
      color: $typography-title

      &::after
        content: ""
        display: block
        position: absolute
        left: 0
        right: 0
        bottom: 0
        height: 2px
        border-radius: 2px
        background-color: $fill-active-primary

  .content
    display: none

    &.active
      display: block

@media screen and (min-width: 1024px)
  .tabs
    display: none