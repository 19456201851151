@use "../../../mixins" as *

.paragraph
  margin-bottom: 16px

  &:last-child
    margin-bottom: 0

  a
    font-weight: bold
    color: $typography-dark-purple