.selectionElement
  display: inline-block
  margin-top: 5px

@media screen and (max-width: 1023px)
  .selectionElement
    margin-right: 12px

  .spacer
    padding-left: 12px

@media screen and (min-width: 1024px)
  .selectionElement
    margin-right: 16px

  .spacer
    padding-left: 16px