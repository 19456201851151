@use "../../../../../_mixins" as *

.text
  text-align: center
  padding: 100px 0
  cursor: pointer
  border-radius: 8px
  border: 1px solid $border-mute

  > div
    &:first-child
      font-size: 24px
      font-weight: bold
      margin-bottom: 4px

    &:nth-child(2)
      font-size: 18px
      margin-bottom: 12px

    &:nth-child(3)
      font-size: 14px