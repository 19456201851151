@use "../../../../_mixins" as *

$logo-height-mobile: 32px
$logo-height-desktop: 40px

.head
  display: flex

  > div
    @include justifyVerticalCenter

.logoWithTitle
  display: flex

  > div
    @include justifyVerticalCenter

    &.titleAndLogo
      padding-left: 8px

.title
  font-weight: bold

@media screen and (max-width: 1023px)
  .head
    position: relative
    top: 0
    left: 0
    right: 0
    justify-content: space-between
    padding: 0
    background-color: $fill-bg-primary
    border-bottom: 0
    margin-bottom: 8px
    z-index: 20

  .logo
    img
      display: block
      height: $logo-height-mobile

  .title
    font-size: 18px

@media screen and (min-width: 1024px)
  .head
    margin-bottom: 13px

    svg
      width: 24px
      height: 24px

  .logo
    margin-right: 12px

    img
      display: block
      min-width: $logo-height-desktop
      height: $logo-height-desktop

  .title
    font-size: 24px