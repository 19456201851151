@use "../../_mixins" as *

.authLayout
  @include justifyVerticalCenter
  position: relative
  width: 100%
  min-height: 100vh
  z-index: 1

.logo
  @include padding
  flex: 1
  padding-top: 24px
  padding-bottom: 24px

.logoImage
  display: inline-block
  padding: 4px

  img
    width: auto
    height: 17px

.tagLine
  @include fontSize-h1
  font-weight: bold
  color: $typography-inverted
  margin-top: 12px

.description
  @include fontSize-h4
  color: $typography-inverted
  margin-top: 4px

.learnMore
  margin-top: 16px

  button,
  button:focus,
  button:focus-visible,
  button:focus-within
    color: $typography-inverted !important
    border-color: $border-white !important
    border-style: solid !important
    background-color: transparent

    svg
      path
        fill: $icon-white !important

.contentInner
  @include borderRadius-top
  @include padding
  width: 100%
  background-color: $fill-bg-primary

@media screen and (max-width: 1023px)
  .learnMore
    button
      padding-top: 12px !important
      padding-bottom: 12px !important

      svg
        width: 18px
        height: 18px

@media screen and (min-width: 1024px)
  .authLayout
    @include padding
    max-width: 1240px
    margin: 0 auto
    flex-direction: row

  .logo,
  .content
    @include justifyVerticalCenter
    width: 50%

  .logo
    padding-right: 140px

  .logoImage img
    height: 36px

  .tagLine
    margin-top: 24px

  .learnMore
    margin-top: 24px

  .contentInner
    @include borderRadius