@use "../../../mixins" as *

.wrapper
  @include justifyVerticalCenter
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 1001

  > div
    padding: 16px
    overflow-y: scroll
    -ms-overflow-style: none
    scrollbar-width: none

    &::-webkit-scrollbar
      display: none

    > div
      margin: 0 auto

.close
  @include shadow
  cursor: pointer
  position: fixed
  top: 16px
  right: 16px
  z-index: 1002
  border-radius: 100%
  background-color: $fill-bg-primary

@media screen and (max-width: 1023px)
  .close
    padding: 8px
    z-index: 9999

@media screen and (min-width: 1024px)
  .close
    padding: 12px

    svg
      width: 32px
      height: 32px