@use "../../../_mixins" as *

.options
  display: none
  background-color: $fill-bg-primary

  &.opened
    display: block

.head
  @include fontSize-h4
  font-weight: bold

@media screen and (max-width: 1023px)
  .options
    border-top-left-radius: 12px
    border-top-right-radius: 12px
    position: fixed
    left: 0
    right: 0
    bottom: 0
    max-height: calc(80vh - 85px)
    overflow-y: auto
    padding-bottom: 87px
    z-index: 999
    animation: slideInFromBottom 0.3s ease-out

  .head
    padding: 32px 16px 18px 16px
    &:before
      @include borderRadius
      width: 28px
      height: 4px
      content: ""
      position: absolute
      top: 10px
      left: 50%
      margin-left: -14px
      background-color: $mute-primary

  .filters
    padding: 0 16px

  .filtersInner
    padding-bottom: 16px

  .actions
    @include shadow
    position: fixed
    padding: 16px
    left: 0
    right: 0
    bottom: 0
    background-color: $fill-bg-primary

    .reset,
    .cancel
      display: none

  .overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: $fill-overlay
    z-index: 998

  @keyframes slideInFromBottom
    0%
      transform: translateY(100%)
      opacity: 0
    100%
      transform: translateY(0)
      opacity: 1

@media screen and (min-width: 1024px)
  .options
    @include shadow
    border-radius: 12px
    position: absolute
    top: calc(100% + 16px)
    right: 0
    margin-left: 14px
    padding: 12px
    z-index: 989

  .head
    margin-bottom: 14px

  .filters
    border: 1px solid $border-mute
    border-radius: 12px
    padding: 12px

  .filtersInner
    display: grid

    > div
      max-height: 460px
      overflow-y: scroll

  .actions
    display: flex
    justify-content: space-between
    margin-top: 14px

    button
      font-size: 16px

    .applyAndCancel
      display: flex

    .reset,
    .cancel
      button
        font-weight: normal

    .cancel
      margin-right: 12px