@use "../../../../mixins" as *

.note
  @include fontSize-14_16
  padding: 12px
  margin-top: 16px
  border-radius: 8px
  background-color: $fill-warning-secondary

.title
  font-weight: bold
  color: $typography-body

.alert
  @include fontSize-12_14
  color: $typography-caption
  display: flex
  padding-top: 8px

  > div
    @include justifyVerticalCenter

    &.icon
      margin-right: 4px
      padding-top: 1px