@use "../../mixins" as *

.summary
  cursor: pointer

.summaryTitle
  @include fontSize-18_24
  font-weight: bold

@media screen and (max-width: 1023px)
  .summary
    margin-bottom: 24px

  .summaryTitle
    margin-bottom: 5px

@media screen and (min-width: 1024px)
  .summary
    margin-bottom: 32px

  .summaryTitle
    margin-bottom: 9px