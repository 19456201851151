@use "../../../_mixins" as *

.row
  display: grid
  gap: 16px
  margin-bottom: 12px

.rowTwoColumns
  @include gridTwoColumns
  composes: row

  &.leftGrow
    display: flex
    > div:first-child
      flex: 1

  &.rightGrow
    display: flex
    > div:last-child
      flex: 1

@media screen and (min-width: 1024px)
  .row
    gap: 24px
    margin-bottom: 24px