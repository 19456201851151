@use "../../../../_mixins" as *

.tile
  @include borderRadius
  @include padding
  margin-top: 16px
  background-color: $fill-bg-primary

.title
  @include fontSize-h3
  font-weight: bold
  color: $typography-title
  display: flex
  margin-bottom: 9px

  > div
    @include justifyVerticalCenter

@media screen and (max-width: 1023px)
  .tile
    &.noLayoutMobile
      padding: 0
      background-color: transparent

      .title
        display: none

@media screen and (min-width: 1024px)
  .tile
    margin-top: 32px

    &.noLayoutDesktop
      padding: 0
      background-color: transparent

    &.noLayoutDesktop,
    &.hideTitleDesktop
      > .title
        display: none

  .title
    margin-bottom: 12px