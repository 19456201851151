@use "../../../mixins" as *

.icon
  margin-bottom: 16px

.title
  @include fontSize-h3
  font-weight: bold
  margin-bottom: 8px

.text
  @include fontSize-16_18

@media screen and (min-width: 1024px)
  .title
    margin-bottom: 12px