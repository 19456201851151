@use "../../../_mixins" as *

.banner
  @include borderRadius
  font-size: 39px
  line-height: 51px
  color: $typography-inverted
  padding: 25px 32px
  margin-bottom: 24px
  cursor: pointer
  background-color: $fill-active-primary
  background-image: url('./exclusive-deals-background.png')
  background-position: left bottom
  background-repeat: no-repeat
  background-size: 170%

.exclusive
  font-weight: bold

.arrow
  display: flex
  justify-content: flex-end
  margin-top: 32px

@media screen and (min-width: 480px)
  .banner
    background-position: right bottom
    background-size: 140%

@media screen and (min-width: 768px)
  .banner
    display: flex
    background-position: right 60px bottom
    background-size: 626px

  .arrow
    flex: 1
    > div
      display: flex
      flex-direction: column
      justify-content: flex-end
      padding-bottom: 5px

@media screen and (min-width: 1024px)
  .banner
    font-size: 55px
    line-height: 72px
    padding: 32px
    margin-bottom: 32px