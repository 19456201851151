@use "../../_mixins" as *

.alert
  @include fontSize-14_16
  display: flex
  padding: 8px
  border-radius: 4px

  &.small
    @include fontSize-12_14

.icon
  margin-right: 8px
  padding-top: 1px

.icon,
.text
  @include justifyVerticalCenter

.alertInfo
  composes: alert
  color: $typography-inactive
  background-color: $fill-bg-secondary

.alertSuccess
  composes: alert
  color: $typography-active
  background-color: $fill-success-secondary

.alertWarning
  composes: alert
  color: $typography-active
  background-color: $fill-warning-secondary

.alertError
  composes: alert
  color: $typography-active
  background-color: $fill-error-secondary