@use "../../_mixins" as *

.line
  display: flex
  flex-wrap: wrap
  margin-bottom: -8px

.tag
  @include fontSize-12_14
  color: $typography-caption
  white-space: break-spaces
  margin-bottom: 8px

.tagWithLink
  composes: tag

  &:hover
    text-decoration: underline

.bright
  color: $typography-inverted

.separator
  @include justifyVerticalCenter
  margin: 0 8px 8px 8px

  div
    width: 4px
    height: 4px
    margin: auto
    background-color: $mute-primary

  &.bright
    div
      background-color: $fill-bg-primary