@use "../../mixins" as *

.footer
  @include fontSize-12_14
  @include shadow
  text-align: center
  background-color: $fill-bg-primary

  a
    color: $typography-inactive
    display: inline-block
    margin: 0 12px

  .wrapper
    width: 100%
    max-width: $wrapper-max-width
    margin: 0 auto
    padding: 24px 16px

@media screen and (min-width: 1024px)
  .footer
    .wrapper
      padding: 24px 32px