@use "../../../../../_mixins" as *

.percentage
  color: $typography-inactive
  display: flex

  > div
    @include justifyVerticalCenter

.indicator
  width: 12px
  margin-top: 1px