@use "../../../../../../_mixins" as *

@media screen and (max-width: 1023px)
  .industry
    padding-top: 12px

  .titleDesktop
    display: none

@media screen and (min-width: 1024px)
  .industry
    display: flex
    flex-direction: column
    height: 100%
    padding: 24px
    border-radius: 8px
    border: 1px solid $border-mute

    > div
      &:nth-child(2)
        flex: 1

  .titleDesktop
    @include fontSize-h4
    text-align: center
    margin-bottom: 12px