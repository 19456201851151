@use "../../../_mixins" as *

.card
  @include borderRadius
  position: relative
  cursor: pointer
  margin-bottom: 16px
  border: 1px solid $border-mute
  background: $fill-bg-primary

  &:last-child
    margin-bottom: 0 !important

.part1
  background-size: cover !important

  .tag
    color: $typography-inverted !important

@media screen and (max-width: 1023px)
  .part1
    @include borderRadius-top
    padding: 16px

  .part2
    @include padding-top
    padding-left: 16px
    padding-right: 16px

  .part3.withContent
    margin: 16px
    padding: 8px
    border-radius: 8px
    border: 1px solid $border-mute

  .categorization
    margin-top: 9px

@media screen and (min-width: 1024px)
  .card
    margin-bottom: 28px

  .parts
    display: flex

  .part1
    @include borderRadius-left
    width: 20%
    display: flex
    flex-direction: column
    justify-content: flex-end
    background-position: center !important
    background-size: cover !important

  .part2
    width: 47.6%
    border-right: 1px solid $border-mute

  .part3
    width: 32.40%
    @include justifyVerticalCenter

  .part1,
  .part3,
  .part2
    padding: 24px

  .categorization
    margin-top: 12px