@use "../../_mixins" as *

.header
  @include padding-left
  @include padding-right
  position: fixed
  top: 0
  left: 0
  right: 0
  padding-top: 16px
  padding-bottom: 16px
  border-bottom: 1px solid $border-mute
  background-color: $fill-bg-primary
  z-index: 990

.headerInner
  @include wrapper
  display: flex

.logo
  @include justifyVerticalCenter
  flex: 1

.defaultLogo
  img
    height: 17px

.defaultLogo
  display: flex

  img
    width: auto

.toggle
  @include justifyVerticalCenter
  cursor: pointer

@media screen and (max-width: 1023px)
  .menuHidden
    display: none

@media screen and (min-width: 1024px)
  .logo
    flex: none
    margin-right: 40px

    > div
      cursor: pointer

  .menu,
  .menuHidden
    flex: 1

  .toggle
    display: none

@media screen and (min-width: 1280px)
  .header
    @include shadow
    border: 0