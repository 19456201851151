@use "../../_mixins" as *

.wrapper
  position: relative
  min-height: 100vh
  z-index: 1
  padding: 16px

  &.centerVertically
    @include justifyVerticalCenter

.content
  width: 100%
  max-width: $wrapper-max-width
  margin: 0 auto

@media screen and (min-width: 1024px)
  .wrapper
    padding: 32px