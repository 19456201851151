@use "../../../../_mixins" as *

.modalTitle
  @include fontSize-16_18
  font-weight: bold
  color: $typography-dark-purple
  margin-bottom: 16px

.modalDescription
  @include fontSize-14_16

.modalNotice
  @include fontSize-12_14

@media screen and (max-width: 1023px)
  .modalDescription
    margin-bottom: 16px

  .title
    display: none

  .checkbox
    margin-bottom: 16px

@media screen and (min-width: 1024px)
  .modalDescription
    margin-bottom: 24px

  .action
    margin-bottom: 80px

  .checkbox
    margin-bottom: 24px

  .button
    button
      > div
        &:first-child
          flex: 1
          text-align: left