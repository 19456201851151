@use "../../form-mixins.module" as *

.label
  @include label

  input
    @include input
    padding-right: 58px

.toggleWrapper
  position: relative

.toggle
  cursor: pointer
  position: absolute
  top: 16px
  right: 16px