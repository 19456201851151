@use "../../../../mixins" as *

.head
  @include fontSize-h1
  font-weight: bold

.description
  @include fontSize-14_16
  color: $typography-caption


.buttons
  display: grid

  &.withDeleteButton
    grid-template-columns: repeat(2, 1fr)

.delete
  margin-top: 24px

@media screen and (max-width: 1023px)
  .head
    margin-bottom: 2px

  .description
    margin-bottom: 16px

  .alert
    margin-top: -8px
    margin-bottom: 16px

  .buttons
    gap: 16px

@media screen and (min-width: 1024px)
  .head
    margin-bottom: 6px

  .description
    margin-bottom: 24px

  .alert
    margin-top: -12px
    margin-bottom: 24px

  .buttons
    gap: 24px