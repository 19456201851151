.buttons
  display: grid
  grid-template-columns: repeat(2, 1fr)

.text
  margin-bottom: 24px

@media screen and (max-width: 1023px)
  .buttons
    gap: 16px

@media screen and (min-width: 1024px)
  .buttons
    gap: 24px