@use "../../../../_mixins" as *

.section
  padding-bottom: 12px

.deSelectAll
  @include borderDashed1pxBottom
  padding: 12px 8px 20px 8px
  margin-bottom: 8px

@media screen and (min-width: 1024px)
  .deSelectAll
    min-width: 220px