@use "../../../../../_mixins" as *

.bar,
.barLive
  height: 10px
  border: 1px solid $border-mute
  border-radius: 40px

  .fill
    height: 8px
    border-radius: 40px

.bar
  .fill
    background-color: $fill-neutral-primary

.barLive
  .fill
    background-color: $fill-success-primary

.texts
  @include fontSize-12_14
  color: $typography-inactive
  display: flex
  justify-content: flex-end
  position: relative
  margin-top: 12px

  .minBar,
  .minText
    display: flex
    justify-content: center
    position: absolute
    width: 1px
    white-space: nowrap

  .minBar
    &:before
      position: absolute
      top: -26px
      width: 1px
      height: 20px
      border-left: 1px dashed $mute-primary
      content: ""
      display: block

  .minText
    &.leftAligned
      width: auto