@use "../../_mixins" as *

.background
  position: relative
  background-size: cover
  background-position: center

.backgroundGradient
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 0
  background: linear-gradient(84deg, #000 4.5%, rgba(0, 0, 0, 0.85) 51.11%, rgba(0, 0, 0, 0.45) 96.77%)

.content
  min-height: 100vh

@media screen and (max-width: 480px)
  .background
    background-image: url('./background/background-480.jpg')

@media screen and (min-width: 481px) and (max-width: 768px)
  .background
    background-image: url('./background/background-768.jpg')

@media screen and (min-width: 769px) and (max-width: 1280px)
  .background
    background-image: url('./background/background-1280.jpg')

@media screen and (min-width: 1281px) and (max-width: 2000px)
  .background
    background-image: url('./background/background-2000.jpg')

@media screen and (min-width: 2001px)
  .background
    background-image: url('./background/background-2560.jpg')