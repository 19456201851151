@use "../../../../../mixins" as *

.aiStatusDeclaration form
  @include borderRadius
  margin-top: 16px
  padding: 12px
  background-color: $fill-bg-primary

.description
  @include fontSize-16_18

@media screen and (min-width: 1024px)
  .aiStatusDeclaration form
    padding: 24px
    margin: 24px 0