@use "../../_mixins" as *
@forward "../../_mixins"
@mixin label
  display: block
  width: 100%

@mixin inputBorder
  border: 1px solid $border-inactive
  outline: 0
  -moz-appearance: none
  -webkit-appearance: none
  &:hover
    border-color: $border-hover !important
  &:focus,
  &:focus-visible
    border-color: $border-focus !important
  &.withError
    border-color: $border-error !important

@mixin input
  @include borderRadius
  @include fontSize-16_18
  @include inputBorder
  @include padding-16
  width: 100%
  line-height: 0

  &:disabled
    cursor: not-allowed