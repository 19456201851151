@use "../../../../../../_mixins" as *

.takePicture
  @include justifyVerticalCenter
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: $fill-active-primary
  z-index: 999

.video
  display: flex

  canvas
    display: none

.close
  position: absolute
  top: 12px
  right: 12px

.error
  @include fontSize-18_24
  color: $typography-error
  font-weight: bold
  text-align: center
  padding: 36px

.trigger
  width: 64px
  height: 64px
  position: absolute
  left: 50%
  bottom: 40px
  margin-left: -32px
  border: 2px solid $fill-bg-primary
  border-radius: 100%
  background-color: transparent

  &:after
    display: block
    position: absolute
    top: 50%
    left: 50%
    content: ""
    width: 52px
    height: 52px
    margin-top: -26px
    margin-left: -26px
    border-radius: 100%
    background-color: $fill-bg-primary