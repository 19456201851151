@use "../../../../../mixins" as *

@media screen and (max-width: 1023px)
  .lastUpdated
    padding: 8px
    margin-top: 12px
    border-radius: 4px
    background-color: $fill-bg-secondary

    > div
      margin: 0

@media screen and (min-width: 1024px)
  .lastUpdated
    display: none

    &.showOnDesktop
      display: block