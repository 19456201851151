@use "../../../../../mixins" as *

.option
  font-size: 18px
  display: block
  width: 100%
  padding: 12px 8px
  text-align: left
  border: 0
  background-color: transparent
  white-space: nowrap
  appearance: none
  -moz-appearance: none
  -webkit-appearance: none

  > div
    display: flex

    > div
      @include justifyVerticalCenter

      &:first-child
        margin-right: 8px

@media screen and (max-width: 1023px)
  .option
    margin-bottom: 8px

    &:last-child
      margin-bottom: 0

@media screen and (min-width: 1024px)
  .option
    border-radius: 4px
    cursor: pointer
    min-width: 350px

    &:hover
      background-color: $fill-hover