@use "../../../../mixins" as *

.legend
  display: flex !important
  flex-wrap: wrap !important
  justify-content: center !important
  max-width: 90%
  margin-top: 28px
  margin-left: auto
  margin-right: auto

  *
    @include fontSize-12_14
    color: $typography-inactive

  > div
    cursor: default
    margin: 2px 4px !important

    &:first-child
      margin-left: 0 !important

    &:last-child
      margin-right: 0 !important