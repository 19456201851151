@use "../../../../../mixins" as *

.fees
  margin-top: 20px
  padding-bottom: 4px

  .head
    display: flex

    > div
      @include justifyVerticalCenter

    .title
      font-size: 16px
      font-weight: 500
      padding-right: 8px

  .tooltip
    > div
      margin-bottom: 12px

      &:last-child
        margin-bottom: 0

  .table
    @include fontSize-14_16

    > div
      display: flex
      justify-content: space-between
      margin-top: 12px

      > div
        &:first-child
          color: $typography-caption

        &:last-child
          color: $typography-inactive

  .setupFeeExplanation
    color: $typography-inactive

@media screen and (min-width: 1024px)
  .fees
    margin-top: 28px

    .head
      .title
        font-size: 21px