@use "../../../../_mixins" as *
@use "../../form-mixins.module" as *

.wrapper
  display: flex

.input
  padding-right: 10px

  input[type="checkbox"]
    @include inputBorder
    position: relative
    display: block
    width: 24px
    height: 24px
    line-height: 0
    border-radius: 4px

    &:checked:after
      display: block
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      content: ""
      border-radius: 4px
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkNoZWNrIj4KPHBhdGggaWQ9IlZlY3RvciIgZD0iTTE0LjM1MzggNC44NTQwM0w2LjM1Mzc4IDEyLjg1NEM2LjMwNzM1IDEyLjkwMDUgNi4yNTIyIDEyLjkzNzQgNi4xOTE1IDEyLjk2MjZDNi4xMzA4MSAxMi45ODc3IDYuMDY1NzQgMTMuMDAwNyA2LjAwMDAzIDEzLjAwMDdDNS45MzQzMyAxMy4wMDA3IDUuODY5MjYgMTIuOTg3NyA1LjgwODU2IDEyLjk2MjZDNS43NDc4NiAxMi45Mzc0IDUuNjkyNzIgMTIuOTAwNSA1LjY0NjI4IDEyLjg1NEwyLjE0NjI4IDkuMzU0MDNDMi4wNTI0NiA5LjI2MDIxIDEuOTk5NzYgOS4xMzI5NiAxLjk5OTc2IDkuMDAwMjhDMS45OTk3NiA4Ljg2NzYgMi4wNTI0NiA4Ljc0MDM1IDIuMTQ2MjggOC42NDY1M0MyLjI0MDEgOC41NTI3MSAyLjM2NzM1IDguNSAyLjUwMDAzIDguNUMyLjYzMjcyIDguNSAyLjc1OTk2IDguNTUyNzEgMi44NTM3OCA4LjY0NjUzTDYuMDAwMDMgMTEuNzkzNEwxMy42NDYzIDQuMTQ2NTNDMTMuNzQwMSA0LjA1MjcxIDEzLjg2NzQgNCAxNCA0QzE0LjEzMjcgNCAxNC4yNiA0LjA1MjcxIDE0LjM1MzggNC4xNDY1M0MxNC40NDc2IDQuMjQwMzUgMTQuNTAwMyA0LjM2NzYgMTQuNTAwMyA0LjUwMDI4QzE0LjUwMDMgNC42MzI5NiAxNC40NDc2IDQuNzYwMjEgMTQuMzUzOCA0Ljg1NDAzWiIgZmlsbD0iI2ZmZmZmZiIvPgo8L2c+Cjwvc3ZnPgo=")
      background-position: center center
      background-repeat: no-repeat
      background-color: $fill-active-primary

.label
  display: flex
  flex: 1

  label
    @include fontSize-14_16
    color: $typography-caption
    cursor: pointer
    line-height: normal

    &.fullWidth
      flex: 1

    a
      color: $typography-title

.labelWithError
  composes: label
  label
    color: $typography-error

  svg
    fill: $fill-error-primary

.description
  display: block

.input,
.label label,
.exclamationMark
  @include justifyVerticalCenter

.exclamationMark
  padding-left: 8px

  &.fullWidth
    padding-left: 4px