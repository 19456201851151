@use "../../../../_mixins" as *

.menu
  display: flex

  > a
    @include justifyVerticalCenter
    cursor: pointer

.help
  @include fontSize-14_16
  color: $typography-inactive
  display: flex
  padding: 16px
  margin-right: 24px

  > div
    @include justifyVerticalCenter

    &:first-child
      margin-right: 8px

.helpLink
  &.active,
  &:hover
    fill: $icon-theme !important
    text-decoration: none

    .help
      font-weight: bold
      color: $typography-active

      svg path
        fill: $icon-theme

@media screen and (max-width: 1023px)
  .menu
    display: none