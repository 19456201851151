@use "../../../../_mixins" as *

.title
  display: none

@media screen and (min-width: 1024px)
  .title
    font-size: 20px
    display: flex
    margin-bottom: 12px

    > div
      @include justifyVerticalCenter