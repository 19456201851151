@use "../../../../mixins" as *

.entry
  margin-bottom: 16px

  &.opened
    .content
      grid-template-rows: 1fr

.head
  @include fontSize-16_18
  display: flex
  font-weight: bold
  color: $typography-title
  cursor: pointer

  .bullet
    width: 8px
    height: 8px
    margin-left: 5px
    margin-right: 12px
    border-radius: 999px
    background-color: $icon-theme-light

  .toggle
    padding-left: 12px

  > div
    @include justifyVerticalCenter

    &:nth-child(2)
      flex: 1

.content
  display: grid
  grid-template-rows: 0fr
  transition: grid-template-rows 0.4s ease-out

  .inner
    overflow: hidden

    > div
      padding-top: 8px
      padding-left: 26px
      padding-bottom: 12px

  p
    &:first-child
      margin-top: 0

    &:last-child
      margin-bottom: 0

@media screen and (max-width: 1023px)

@media screen and (min-width: 1024px)