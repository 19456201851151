@use "../../../../_mixins" as *

.head
  margin-bottom: 16px

  > div
    @include justifyVerticalCenter

.headElement
  > div:first-child
    margin-bottom: 0
    white-space: nowrap

.buttonElement
  > div:first-child
    margin-right: 0

.title
  flex: 1

@media screen and (min-width: 1024px)
  .head
    display: flex
    margin-bottom: 24px