@use "../../_mixins" as *

.head
  @include fontSize-18_24
  font-weight: bold
  color: $typography-title
  margin-bottom: 16px

.description
  @include fontSize-16_18
  margin-bottom: 16px

@media screen and (min-width: 1024px)
  .head
    margin-bottom: 24px

  .description
    margin-bottom: 24px

    p
      margin-bottom: 12px

      &:last-child
        margin-bottom: 0