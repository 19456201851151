@use "../../../../../../_mixins" as *

.file
  display: flex
  padding: 16px
  border: 1px solid $border-mute
  border-radius: 8px

  &.clickable
    cursor: pointer

.content
  flex: 1

  .title
    font-weight: bold

  .fileNameAndIcon
    display: flex
    margin-top: 8px

    .fileName
      @include fontSize-14_16
      padding-left: 6px

.download
  padding-left: 16px

