@use "../../../_mixins" as *

.wrapper
  @include justifyVerticalCenter
  position: relative
  min-height: 100vh
  z-index: 1
  padding: 16px

.content
  width: 100%
  max-width: $wrapper-max-width
  margin: 0 auto

.notPubliclyAvailable
  @include borderRadius
  @include shadow
  width: 100%
  max-width: 600px
  padding: 16px
  margin: 0 auto
  background-color: $fill-bg-primary

  .button
    margin-top: 24px

@media screen and (min-width: 1024px)
  .wrapper
    padding: 32px

  .container
    display: flex

    .left
      width: 68%
      order: 1

    .right
      width: 32%
      order: 2
      padding-left: 24px