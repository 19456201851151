@use "../../../../_mixins" as *

.notice
  @include borderRadius
  @include fontSize-14_16
  color: $typography-inactive
  padding: 16px
  background-color: $fill-bg-secondary


@media screen and (min-width: 1024px)
  .notice
    padding: 24px