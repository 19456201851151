.submitButton
  margin-top: 24px

@media screen and (min-width: 1024px)
  .submitButton
    &.right
      display: flex
      justify-content: flex-end

      button
        width: auto

        > div:last-child
          padding-left: 40px