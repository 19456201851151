@use "../../mixins" as *

.help
  .head
    margin-bottom: 32px

  &.unauthenticated
    .head
      > div
        &:first-child
          @include fontSize-h3