@use "../../../../../_mixins" as *

.status
  @include fontSize-14_16
  display: flex
  padding: 12px 16px
  border-radius: 8px
  border: 1px solid $border-mute

  > div
    @include justifyVerticalCenter

  .dot
    width: 7px
    height: 7px
    margin-bottom: 1px
    border-radius: 100%
    background-color: $fill-success-primary

  .statusText
    font-weight: bold
    color: $typography-title
    margin-left: 7px
    margin-right: 7px

  .text
    color: $typography-success

  &.red
    .dot
      background-color: $fill-error-primary

    .text
      color: $typography-error

  &.yellow
    .dot
      background-color: $icon-warning

    .text
      color: $typography-warning