@use "../../../mixins" as *

.loadingCircle
    display: block
    position: relative

    div
        box-sizing: border-box
        display: block
        position: absolute
        border-radius: 50%
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
        border-width: 2px
        border-style: solid
        border-color: $icon-theme-light transparent transparent transparent

        &:nth-child(1)
            animation-delay: -0.45s

        &:nth-child(2)
            animation-delay: -0.3s

        &:nth-child(3)
            animation-delay: -0.15s

@keyframes lds-ring
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)