@use "../../../../_mixins" as *

.event
  @include borderDashed1pxTop
  margin-top: 24px
  padding-top: 24px

  > div
    padding: 16px
    border: 1px solid $border-mute
    border-radius: 12px

  strong
    display: block
    margin-bottom: 8px

.addToCalendar
  @include buttonNotStyled
  @include fontSize-14_16
  margin-top: 12px
  padding: 12px 16px
  border-radius: 12px
  border: 1px solid $border-active

  &:hover
    background-color: $fill-hover

  a
    color: $typography-active
    display: flex

    > div
      @include justifyVerticalCenter

      &:first-child
        margin-right: 8px

@media screen and (min-width: 1024px)
  .event
    > div
      display: flex
      justify-content: space-between

  .addToCalendar
    margin-top: 0