$border-active: #33005B
$border-disabled: #CED3D7
$border-error: #D7263D
$border-focus: #040624
$border-hover: rgba(4, 6, 36, 0.30)
$border-inactive: #85929B
$border-mute: #EEF0F2
$border-neutral-primary: #20BCDE
$border-warning: #F6C126
$border-white: #FFFFFF

$fill-active-primary: #040624
$fill-active-secondary: #D0C6DA
$fill-bg-primary: #FFFFFF
$fill-bg-secondary: #FAFAFA
$fill-bg-tertiary: #EEF0F2
$fill-bg-quaternary: #F7F0FE
$fill-error-primary: #D7263D
$fill-error-secondary: #F1CDD4
$fill-error-tertiary: #FFEFF1
$fill-hover: rgba(4, 6, 36, 0.10)
$fill-inactive-primary: #CED3D7
$fill-inactive-secondary: #EEF0F2
$fill-overlay: rgba(25, 25, 25, 0.60)
$fill-neutral-primary: #20BCDE
$fill-neutral-secondary: #CCEBF4
$fill-neutral-tertiary: #EEF9FA
$fill-success-primary: #009C11
$fill-success-secondary: #C6E5CB
$fill-success-tertiary: #ECF7EE
$fill-warning-secondary: #F7ECD0

$icon-black: #000000
$icon-disabled: #CED3D7
$icon-error: #D7263D
$icon-inactive: #85929B
$icon-quaternary: #F7F0FE
$icon-success: #009C11
$icon-theme: #33005B
$icon-theme-light: #040624
$icon-warning: #F6C126
$icon-white: #FFFFFF

$mute-primary: #EBEDEF

$typography-active: #040624
$typography-black: #000000
$typography-body: #333333
$typography-caption: #4D4D4D
$typography-dark-purple: #1F0B4D
$typography-disabled: #CED3D7
$typography-error: #D7263D
$typography-inactive: #85929B
$typography-inverted: #FFFFFF
$typography-neutral: #20BCDE
$typography-success: #009C11
$typography-title: #040624
$typography-warning: #F6C126


$wrapper-max-width: 1360px