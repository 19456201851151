@use "../../../../../_mixins" as *

.status
  @include fontSize-14_16
  display: flex

  > div
    @include justifyVerticalCenter
    margin-right: 5px

.dot
  > div
    width: 8px
    height: 8px
    border-radius: 100%
    background-color: $fill-neutral-primary

.dotLive
  composes: dot
  > div
    background-color: $fill-success-primary

.text
  display: flex

.textStatus
  font-weight: bold
  color: $typography-title

.textAmount
  color: $typography-caption

@media screen and (min-width: 1024px)
  .status
    > div
      margin-right: 7px